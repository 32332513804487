import { styled, css } from "styled-components";
import theme from "styled-theming";

import { Container as KeyFigure } from "components/KeyFigure/KeyFigure.styled";
import { Container as GridContainer } from "style/components/Page";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

const textColor = theme("theme", {
    lightgray: "--color-black",
    cyan: "--color-blue",
    blue: "--color-cyan-l60",
});

export const Container = styled(GridContainer)`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding-bottom: 2.625rem;
    padding-top: 2.625rem;
    position: relative;

    ${MQ.FROM_M} {
        padding-bottom: 4.375rem;
        padding-top: 4.375rem;
    }

    ${MQ.FROM_XL} {
        padding-bottom: 6.5625rem;
        padding-top: 6.5625rem;
    }
`;

export const KeyFigures = styled.div.attrs<{
    $count: number;
}>(() => ({}))`
    ${columnGap};
    color: var(${textColor});
    display: grid;
    grid-template-columns: repeat(1, [col-start] 1fr);
    row-gap: 2.1875rem;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(2, [col-start] 1fr);
        row-gap: 2.625rem;
    }

    ${MQ.FROM_L} {
        row-gap: 2.625rem;
        grid-template-columns: repeat(3, [col-start] 1fr);
        ${(props) =>
            props.$count == 4
                ? css`
                      ${KeyFigure}:nth-child(3) {
                          grid-column: 2;
                      }
                  `
                : props.$count == 5
                  ? css`
                        ${KeyFigure}:nth-child(4) {
                            grid-column: 2;
                        }
                    `
                  : ""}
    }

    ${MQ.FROM_XL} {
        row-gap: 4.375rem;
    }
`;
