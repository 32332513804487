import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import KeyFigureProps from "./KeyFigureProps";

const KeyFigure = loadable(
    (): Promise<DefaultComponent<KeyFigureProps>> =>
        import(/* webpackChunkName: "KeyFigure" */ "./KeyFigure"),
);
const KeyFigureLoader = (props: KeyFigureProps): ReactElement => (
    <KeyFigure {...props} />
);
export default KeyFigureLoader;
