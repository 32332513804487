import { styled } from "styled-components";

import { default as DefaultIcon } from "components/Icon";
import { styleBodyL, styleHeadingXL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled.div`
    ${columnGap};
    display: grid;
    grid-auto-rows: auto auto 1fr;
    grid-template-columns: repeat(4, [col-start] 1fr);
`;

export const Icon = styled(DefaultIcon)`
    color: var(--icon-decorative-coral);
    grid-column: col-start 1 / span 4;
    grid-row: 1;
`;

export const Number = styled.h2`
    ${styleHeadingXL}
    grid-column: col-start 1 / span 4;
    grid-row: 2;
    margin-bottom: 0.4375rem;
    margin-top: 0.875rem;

    ${MQ.FROM_XL} {
        margin-bottom: 0.875rem;
        margin-top: 1.3125rem;
    }
`;

export const Text = styled.div`
    ${styleBodyL};
    grid-column: col-start 1 / span 4;
    grid-row: 3;
`;
