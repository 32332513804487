import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import KeyFigure from "components/KeyFigure";
import Section from "components/Section";
import { addEditAttributes } from "utils/episerver";

import { Container, KeyFigures } from "./KeyFiguresList.styled";
import KeyFiguresListProps from "./KeyFiguresListProps";

const KeyFiguresList = ({
    identifier,
    theme,
    keyFigures,
    anchorName,
}: KeyFiguresListProps): ReactElement => {
    return (
        <Section id={anchorName} theme={theme} alignItems="center">
            <ThemeProvider
                theme={{
                    theme: theme,
                }}
            >
                <Container
                    key={identifier || `KeyFiguresListContent`}
                    {...addEditAttributes("KeyFigures")}
                >
                    <KeyFigures $count={keyFigures ? keyFigures.length : 0}>
                        {keyFigures?.map((keyFigure, index) => (
                            <KeyFigure
                                key={`KeyFigure${index}`}
                                id={
                                    anchorName
                                        ? `${anchorName}-${index}`
                                        : identifier
                                          ? `${identifier}-${index}`
                                          : ""
                                }
                                delay={(index + 1) * 0.2}
                                {...keyFigure}
                            />
                        ))}
                    </KeyFigures>
                </Container>
            </ThemeProvider>
        </Section>
    );
};

export default React.memo(KeyFiguresList);
